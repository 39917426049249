<template>
  <div class="header">
    <div class="header_login">
      <div @click="logoOut()">
        <img src="@/assets/img/system/header.png" class="img" />
      </div>
      <ul class="header_login_function">
        <li class="cur_point">
          <el-badge class="item" type="warning" :is-dot='isNotice'>
            <div class="iconfont icon-xiaoxi" @click="goToCenterTZ()"></div>
          </el-badge>
          <!-- <el-badge is-dot class="item" type="warning"
            ><div class="iconfont icon-xiaoxi"></div
          ></el-badge> -->
        </li>
        <li class="header_login_function_out" @click.stop="(showOut = !showOut), (lang = false)">
          <div class="messagebox cur_point" v-show="this.userInfo.roleId==1">
            <div class="iconfont icon-qiuzhirenyuan cur_point messageboxright"></div>
            <div class="message">供应商</div>
          </div>
          <div class="messagebox cur_point" v-show="this.userInfo.roleId==3">
            <div class="iconfont icon-hehuoren cur_point messageboxright"></div>
            <div class="message">核心企业</div>
          </div>
          <div class="messagebox cur_point" v-show="this.userInfo.roleId==2">
            <div class="iconfont icon-fuzeren cur_point messageboxright"></div>
            <div class="message">资方</div>
          </div>
          <div class="messagebox cur_point" v-show="this.userInfo.roleId==4">
            <div class="iconfont icon-renyuan cur_point messageboxright"></div>
            <div class="message">服务商</div>
          </div>
          <ul v-if="showOut" class="header_login_function_out_div">
            <li class="cur_point" @click="goToCenter" v-show="myNone">个人中心</li>
            <li @click="getout" class="cur_point">登 出</li>
          </ul>
        </li>
        <li class="header_lang" @click.stop="(lang = !lang), (showOut = false)">
          <span class="cur_point">{{ langFont }}</span>
          <div class="iconfont icon-jiantou cur_point"></div>
          <ul v-if="lang" class="header_login_function_out_div about">
            <li @click="langFont = '中文'" class="cur_point">中文</li>
            <li @click="langFont = '英文'" class="cur_point">英文</li>
          </ul>
        </li>
      </ul>
    </div>
    <div class="header_menu_box" v-show="this.none">
      <div class="limit_width">
        <div class="person_back" v-show="isPersonCenter">
          <router-link :to="backUrl" class="flex_center_wrap"><i class="iconfont icon-fanhui"></i>返回我的工作台</router-link>
        </div>
        <ul class="header_menu_list">
          <template v-for="(item, index) in menuList">
            <li :key="index" class="header_menu_item" v-if="!item.roleId">
              <el-tooltip :key="index" effect="light" :content="item.prom" popper-class="prom_item" :disabled="toolFlag">
                <router-link :to="item.path">{{ item.name }}</router-link>
              </el-tooltip>
            </li>
            <template v-else>
              <li :key="index" class="header_menu_item" v-if="isInclude(item.roleId)">
                <el-tooltip :key="index" effect="light" :content="item.prom" popper-class="prom_item">
                  <router-link :to="item.path">{{ item.name }}</router-link>
                </el-tooltip>
              </li>
            </template>
          </template>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { findUserNotiList } from "@/api/centerPerson.js";
import { loginOut } from "@/api/api.js";
export default {
  name: "Header",
  props: {
    id: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      menuList: [],
      menuListMy: [],
      menuList1: [
        {
          name: "我的工作台",
          prom: '方便进行重要数据的浏览及统计',
          path: "/system/supplier/workbench",
        },
        {
          name: "我的发票",
          prom: '使用发票进行融资申请，及对发票进行管理',
          path: "/system/supplier/applyFinancing",
        },
        {
          name: "我的融资",
          prom: '对融资洽谈、签约等一系列操作的管理',
          path: "/system/supplier/myFinancing",
        },
        {
          name: "我的还款",
          prom: '融资后管理，进行还款的操作',
          path: "/system/supplier/myAccount",
        },
        {
          name: "我的伙伴",
          prom: '对供应商等合作伙伴的管理',
          path: "/system/supplier/myPartner",
        },
        {
          name: "我的信用",
          prom: '信用、增值服务',
          path: "/system/supplier/myCredit",
        },
        {
          name: "我的获客",
          prom: '根据偏好，获取您想要的客户',
          path: "/system/supplier/myCustomers",
        },
        {
          name: "我的服务",
          prom: '服务订单管理',
          path: "/system/supplier/myService",
        },
        {
          name: "功能定义",
          prom: '偏好、提醒、合同管理',
          path: "/system/supplier/functionDefinition",
        }
      ],
      menuList2: [
        {
          name: "我的工作台",
          prom: '方便进行重要数据的浏览及统计',
          path: "/system/investor/workbench",
        },
        {
          name: "我的公海",
          prom: '寻找优质客户',
          path: "/system/investor/publicResource",
        },
        {
          name: "我的私海",
          prom: '签约流程管理',
          path: "/system/investor/privateResources",
        },
        {
          name: "我的风险池",
          prom: '风险预警提示',
          path: "/system/investor/riskPool",
        },
        {
          name: "我的投资",
          prom: '贷后投资管理',
          path: "/system/investor/postLoanManagement",
        },
        {
          name: "我的服务",
          prom: '服务订单管理',
          path: "/system/investor/myService",
        },
        {
          name: "功能定义",
          prom: '偏好、提醒、合同管理',
          path: "/system/investor/functionDefinition",
        }
      ],
      //核心企业
      menuList3: [
        {
          name: "我的工作台",
          prom: '方便进行重要数据的浏览及统计',
          path: "/system/coreEnterprise/workbench",
        },
        {
          name: "我的发票",
          prom: '使用发票进行融资申请，及对发票进行管理',
          path: "/system/coreEnterprise/applyFinancing",
        },
        {
          name: "我的融资",
          prom: '对融资洽谈、签约等一系列操作的管理',
          path: "/system/coreEnterprise/myFinancing",
        },
        {
          name: "我的供应商",
          prom: '对核心企业等合作供应商的管理',
          path: "/system/coreEnterprise/mySupplier",
        },
        {
          name: "我的客户",
          prom: '对核心企业等合作客户的管理',
          path: "/system/coreEnterprise/myClient",
        },
        {
          name: "我的投资",
          prom: '贷后投资管理',
          path: "/system/coreEnterprise/myInvest",
        },
        {
          name: "我的获客",
          prom: '根据偏好，获取您想要的客户',
          path: "/system/coreEnterprise/myCustomers",
        },
        {
          name: "我的服务",
          prom: '服务订单管理',
          path: "/system/coreEnterprise/myService",
        },
        {
          name: "功能定义",
          prom: '偏好、提醒、合同管理',
          path: "/system/coreEnterprise/functionDefinition",
        },
      ],
      //个人中心
      menuList4: [
        {
          name: "账户管理",
          path: "/system/personalCenter/accountManagement",
        },
        {
          name: "账户设置",
          path: "/system/personalCenter/accountSettings",
        },
        {
          name: "我的评级",
          path: "/system/personalCenter/myRating",
        },
        {
          name: "我的通知",
          path: "/system/personalCenter/myNotice",
        },
      ],
      menuList5: [
        {
          name: "我的通知",
          path: "/system/personalCenter/myNotice",
        },
      ],
      showOut: false,
      lang: false,
      langFont: "中文",
      isPersonCenter: localStorage.getItem("isPersonCenter") || false, //在个人中心页面显示
      backUrl: "",
      userInfo: JSON.parse(localStorage.getItem("userInfo")) || "",
      isNotice: false,//通知高亮
      none: true,
      myNone: true,
      toolFlag: false
    };
  },
  computed: {
    ...mapGetters({
      viewIndex: "viewIndex",
    }),
  },
  created() {
    this.toolFlag = false
    this.getMenu();
    const self = this;
    document.documentElement.onclick = function () {
      self.showOut = false;
      self.lang = false;
    };

    if (this.userInfo && this.userInfo.roleId == '1') {
      //供应商
      this.backUrl = "/system/supplier/workbench";
    } else if (this.userInfo && this.userInfo.roleId == '2') {
      //资方
      this.backUrl = "/system/investor/workbench";
    } else if (this.userInfo && this.userInfo.roleId == '3') {
      //核心企业
      this.backUrl = "/system/coreEnterprise/workbench";
    } else {
      //服务商
      this.backUrl = "/system/service/workbench";
    }

    this.findUserNotiList()
  },
  beforeCreate() {
    if (window.location.hash.split("/")[2] != "personalCenter") {
      localStorage.setItem("isPersonCenter", "");
    }
  },
  methods: {
    logoOut() {
      this.logOut();
    },
    getout() {
      this.logOut();
    },
    getMenu() {
      console.log(this.id, 255);
      console.log(this.userInfo.roleId)
      if (this.id === "1") {
        // console.log(this.$route)
        this.menuList = this.menuList1;
      } else if (this.id === "2") {
        this.menuList = this.menuList2;
      } else if (this.id === "3") {
        this.menuList = this.menuList3;
      } else if (this.id === "4") {
        this.none = false
        this.myNone = false
      } else if ((this.userInfo.roleId === "1" || this.userInfo.roleId === "2" || this.userInfo.roleId === "3")) {
        this.toolFlag = true
        this.menuList = this.menuList4;
      } else if (this.userInfo.roleId == "4") {
        this.menuList = this.menuList5;
        this.myNone = false
      }
    },
    //个人中心
    goToCenter() {
      this.$router.push("/system/personalCenter/accountManagement");
      this.isPersonCenter = true;
      localStorage.setItem("isPersonCenter", true);
    },
    //通知
    goToCenterTZ() {
      this.$router.push("/system/personalCenter/myNotice");
      this.isPersonCenter = true;
      localStorage.setItem("isPersonCenter", true);
    },

    //登出
    async logOut() {
      let res = await loginOut();
      if (res && res.code == 200) {
        // localStorage.removeItem("userInfo");
        localStorage.clear()
        this.$router.push("/");
      } else {
        this.$router.push("/");
      }
    },

    //菜单是否包含角色
    isInclude(roleId) {
      return roleId.includes(this.userInfo.roleId)
    },

    //获取通知列表
    async findUserNotiList() {
      let res = await findUserNotiList("pageNo=-1&pageSize=10")
      if (res && res.code == 200) {
        let list = res.data
        console.log(list)
        if (list) {
          list.forEach(item => {
            if ((item.noticeState == '1')) {
              this.isNotice = true
              return
            }
          })
        }
      }
    },

  },
};
</script>

<style scoped>
  .messagebox {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: auto;
  }
  .messageboxright {
    margin-right: 5px;
  }
  .message {
    font-size: 16px;
    color: #fff;
  }
  ::v-deep .el-badge__content--warning {
    background-color: #ff9b15;
    top: 6px !important;
    right: 9px !important;
    border: 0 !important;
  }
  .limit_width {
    position: relative;
    width: 1500px;
    height: auto;
    margin: 0 auto;
  }
  .icon-gerenzhongxin {
    font-size: 24px;
  }
  .header {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 100;
  }
  .header_login_function {
    display: flex;
    align-content: center;
    justify-content: flex-end;
    align-items: center;
  }
  .iconfont {
    color: #fff;
  }
  .img {
    width: 218px;
    height: 20px;
    cursor: pointer;
  }
  .header_login {
    padding: 0 11%;
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #339c9b;
    z-index: 101;
    position: relative;
  }
  .header_login_function li {
    padding-left: 25px;
  }
  .header_lang {
    position: relative;
    display: flex;
    align-items: center;
    padding-right: 6px;
    line-height: 25px;
    font-size: 18px;
    color: #ffffff;
  }
  .icon-xiaoxi::before,
  .icon-tuichu::before {
    font-size: 24px;
  }
  .header_menu_box {
    width: 100%;
    height: 46px;
    background: rgba(255, 255, 255, 0.8);
    box-shadow: 0px 2px 10px 0px rgba(12, 79, 71, 0.16);
  }

  .header_menu_list {
    display: flex;
    justify-content: center;
  }
  .header_menu_item {
    width: 160px;
    line-height: 20px;
    font-size: 16px;
    padding: 16px 40px 6px 40px;
    text-align: center;
  }
  .header_menu_item a {
    position: relative;
    color: #162747;
  }
  .router-link-active {
    color: #339c9b !important;
  }
  .header_menu_item .router-link-active::after {
    content: '';
    position: absolute;
    bottom: -10px;
    left: 50%;
    width: 80px;
    height: 4px;
    background-color: #339c9b;
    border-radius: 2px;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
  }
  .header_login_function_out {
    position: relative;
  }
  .about {
    right: -20px !important;
  }
  .header_login_function_out_div {
    position: absolute;
    top: 30px;
    right: -24px;
    width: 116px;
    /* height: 133px; */
    padding: 29px 8px 25px 8px;
    background-image: url('../../assets/img/system/headericon1.png');
    background-size: 100% 100%;
    color: #162747;
    font-size: 16px;
  }
  .header_login_function_out_div li:nth-child(1) {
    margin-bottom: 10px;
  }
  .header_login_function_out_div li {
    padding: 0;
    width: 100%;
    height: 34px;
    line-height: 34px;
    text-align: center;
  }
  .header_login_function_out_div li:hover {
    background-color: rgba(51, 156, 155, 0.16);
    color: #339c9b;
  }
  .person_back {
    position: absolute;
    left: 0;
    top: 0;

    padding: 14px 40px 8px 0;
  }
  .person_back a {
    font-size: 16px;
    color: #339c9b;
  }
  .icon-fanhui {
    color: #339c9b;
    font-size: 24px;
    margin-right: 10px;
  }
  @media screen and (max-width: 1664px) {
    .messagebox {
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      width: auto;
    }
    .messageboxright {
      margin-right: 5px;
    }
    .message {
      font-size: 12px;
      color: #fff;
    }
    ::v-deep .el-badge__content--warning {
      background-color: #ff9b15;
      top: 6px !important;
      right: 6px !important;
      border: 0 !important;
    }
    .limit_width {
      position: relative;
      width: 1000px;
      height: auto;
      margin: 0 auto;
    }
    .icon-gerenzhongxin {
      font-size: 16px;
    }
    .img {
      width: 145px;
      height: 13px;
    }
    .header_login {
      padding: 0 11%;
      width: 100%;
      height: 33px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      background-color: #339c9b;
      z-index: 101;
      position: relative;
    }
    .header_login_function li {
      padding-left: 16px;
    }
    .header_login_function {
      line-height: 24px;
    }
    .header_lang {
      position: relative;
      display: flex;
      align-items: center;
      padding-right: 4px;
      line-height: 16px;
      font-size: 12px;
      color: #ffffff;
    }
    .icon-xiaoxi::before,
    .icon-tuichu::before {
      font-size: 16px;
    }
    .header_menu_box {
      width: 100%;
      height: 30px;
      background: rgba(255, 255, 255, 0.8);
      box-shadow: 0px 2px 10px 0px rgba(12, 79, 71, 0.16);
    }

    .header_menu_list {
      display: flex;
      justify-content: center;
    }
    .header_menu_item {
      width: 112px;
      line-height: 20px;
      font-size: 12px;
      padding: 7px 25px 4px 25px;
      text-align: center;
    }
    .header_menu_item .router-link-active::after {
      content: '';
      position: absolute;
      bottom: -6px;
      left: 50%;
      width: 53px;
      height: 2px;
      background-color: #339c9b;
      border-radius: 2px;
      -webkit-transform: translateX(-50%);
      transform: translateX(-50%);
    }
    .about {
      right: -13px !important;
    }
    .header_login_function_out_div {
      position: absolute;
      top: 20px;
      right: -12px;
      width: 77px;
      /* height: 88px; */
      padding: 19px 5px 20px 5px;
      background-image: url('../../assets/img/system/headericon1.png');
      background-size: 100% 100%;
      color: #162747;
      font-size: 12px;
    }
    .header_login_function_out_div li:nth-child(1) {
      margin-bottom: 6px;
    }
    .header_login_function_out_div li {
      padding: 0;
      width: 100%;
      height: 22px;
      line-height: 22px;
      text-align: center;
    }
    .person_back {
      position: absolute;
      left: 0;
      top: 0;
      padding: 9px 26px 5px 0;
    }
    .person_back a {
      font-size: 12px;
      color: #339c9b;
    }
    .icon-fanhui {
      color: #339c9b;
      font-size: 16px;
      margin-right: 6px;
    }
  }
</style>
